import React,{useState ,useReducer, useEffect} from "react";
import { Link ,useHistory, useLocation} from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Image
import profile_h from "../../../images/user.jpg";
import avatar from "../../../images/logomob.png";
import LogoutPage from './Logout';
import PageTitle from "../../layouts/PageTitle";
import { GlobalSet } from '../../components/global/GlobalProvider';
import { Row, Card, Col, Button, Modal, Container, Dropdown ,Badge } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Header = ({ onNote }) => {
  const { CurrencySymbol } = GlobalSet();
  const { BaseUrl } = GlobalSet();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');

  var withdraw = BaseUrl + "public/appssource/sports_icons/withdraw.png";
  var deposit = BaseUrl + "public/appssource/sports_icons/deposit.png";
  var security = BaseUrl + "public/appssource/sports_icons/security.png";
  var support_ticket = BaseUrl + "public/appssource/sports_icons/support_ticket.png";
  var log_out = BaseUrl + "public/appssource/sports_icons/log_out.png";
  var transaction_history = BaseUrl + "public/appssource/sports_icons/transaction_history.png";
  var bet_history = BaseUrl + "public/appssource/sports_icons/bet_history.png";
  var profile = BaseUrl + "public/appssource/sports_icons/profile.png";
  var income_history = BaseUrl + "public/appssource/sports_icons/Income_history.png";

  // Check if ?login is in the URL, and open the modal
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("login")) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [location]);

  // Close the modal and remove ?login from the URL
  const handleClose = () => {
    setShow(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("login");
    history.replace({
      search: searchParams.toString(),
    });
  };

  // Open the modal by adding ?login to the URL
  const handleShowLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("login", "true");
    history.replace({
      search: searchParams.toString(),
    });
  };

  const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post(`/logout`).then(res => {
        	console.log(res.data.message)
            if(res.data.message === 'Unauthenticated')
            {
                localStorage.removeItem('auth_token');
                //swal("Success",res.data.message,"success");
                //history.push('/');
	             swal("Success", res.data.message, "success");
	             window.location.href = '/'; 
            }
        });
    }
      axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if(err.response.status === 401)
        {
        	localStorage.removeItem('auth_token');
            swal("Unauthorized",err.response.data.message,"warning");
            history.push('/');
        }
        return Promise.reject(err);
    });

    const [loginInput, setLogin] = useState({
        username: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            username: loginInput.username,
            password: loginInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
            axios.post(`/apps-user-login`, data).then(res => {
                if(res.data.status === 'success')
                {
                    localStorage.setItem('auth_token', res.data.accessToken);
                    //swal("Success",res.data.message,"success");
                    //history.push('/');
                    swal("Success",res.data.message,"success");
	                window.location.href = '/'; 
 
                }
                else if(res.data.status === 'fail')
                {

                    swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setLogin({...loginInput, error_list: res.data.validation_error});
                }
            });
          }



   const [user, setUser] = useState(null);
   const [balance, setBalance] = useState(0); // Separate balance state
	 const fetchProfileData = () => {
	    const data = {
	      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
	      currency: selectedCurrency,
	    };
	    if (auth_token !== null) {
	      axios.post('/apps-profile', data)
	        .then(response => {
	          setUser(response.data);
	          setBalance(response.data?.data?.balance || 0); // Update balance
	        })
	        .catch(error => {
	          console.log(error);
	        });
	    }
	  };
	  useEffect(() => {
	    fetchProfileData();
	    const intervalId = setInterval(fetchProfileData, 5000);
	    return () => clearInterval(intervalId);
	  }, []);


var AuthButtons = '';
if(!localStorage.getItem('auth_token'))
{
  AuthButtons = (
	<ul className="navbar-nav header-right">
	    <div as="li" className="nav-item">
	        <Button variant="outline-primary btn-square btn-xxs" onClick={handleShowLogin}>
	            <strong>Login</strong>
	        </Button>
	    </div>
	    <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	            <Modal.Title>Login Here</Modal.Title>
	        </Modal.Header>
	         <Modal.Body>
	            <form onSubmit={loginSubmit}>
		            <div className="form-group mb-3">
		               <div className="form-group">
		                  <div className="form-group mb-3">
		                       <label className="mb-1 ">
		                          <strong>Username</strong>
		                        </label>
		                    <input
		                      type="text"
		                      className="form-control"
		                      placeholder="username"
		                       name="username"
		                     onChange={handleInput} 
		                     value={loginInput.username}
		                      />
		                   </div>
		                  <span>{loginInput.error_list.username}</span>
		                 

                        <label className="text-label">Password</label>
                        <div className="input-group transparent-append mb-2">
                          
                            <span className="input-group-text">
                              {" "}
                              <i className="fa fa-lock" />{" "}
                            </span>
                          
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            placeholder="password"
                            name="password"
                            onChange={handleInput} 
                            value={loginInput.password}
                          />

                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >

                              {" "}
								  {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                            
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                          </div>


                        </div>
		                 <span>{loginInput.error_list.password}</span>



		                  <div className="text-center mt-4">
		                      <button type="submit" className="btn btn-primary btn-block">
		                        Login
		                      </button>
		                  </div>
		              </div>
		            </div>
	        	</form>
	        	<div className="new-account mt-3">
					<p><span>New account? <Link className="text-primary" to="./register">Sign up</Link></span>
					<span style={{ float: 'right' }}><Link className="text-primary" to="./forgot-password">Forgot Password ?</Link></span>
					</p>
				</div>
	        </Modal.Body>
	    </Modal>
	    <Dropdown as="li" className="nav-item">
		    <Link to="/register" >
				<Button variant="outline-primary btn-square btn-xxs" >
				    <strong>signup</strong>
				</Button>
			</Link>
		</Dropdown>
	</ul>
   );
    }
    else
    {
  AuthButtons = (
	<ul className="navbar-nav header-right">
	


		<Dropdown as="li" className="nav-item dropdown header-profile">

        <button type="button" className="me-2 btn btn-dark btn-rounded">
          <span className="btn-icon-start text-primary" onClick={fetchProfileData} >
            <i className="ti-reload"></i>
          </span>
          {CurrencySymbol} {parseFloat(parseFloat(balance).toFixed(2))} 
        </button>

			<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
				<img src={profile_h} width={30} alt="Profile" />
			</Dropdown.Toggle>
			<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">

				<Link to="/app-profile" className="dropdown-item ai-icon">
					<img src={profile} alt="app profile" style={{ width: '25px', height: '25px'}} />
					<span className="ms-2"> {t('he_pro_profile')} </span>
				</Link>
				<Link to="/deposit" className="dropdown-item ai-icon">
					<img src={deposit} alt="deposit" style={{ width: '25px', height: '25px'}} />
					<span className="ms-2"> {t('he_pro_dep_his')} </span>
				</Link>					
				<Link to="/mybet-history" className="dropdown-item ai-icon">
					<img src={bet_history} alt="mybet history" style={{ width: '25px', height: '25px'}} />
				  <span className="ms-2"> {t('he_pro_bet_his')} </span>
				</Link>
				<Link to="/income-history" className="dropdown-item ai-icon">
					<img src={income_history} alt="income history" style={{ width: '25px', height: '25px'}} />
					<span className="ms-2"> {t('he_pro_inc_his')} </span>
				</Link>
				 <Link to="/transaction-history" className="dropdown-item ai-icon">
					<img src={transaction_history} alt="transaction history" style={{ width: '25px', height: '25px'}} />
					<span className="ms-2"> {t('he_pro_tra_his')} </span>
				</Link>
				<Link to="/withdraw" className="dropdown-item ai-icon">
					<img src={withdraw} alt="withdraw" style={{ width: '25px', height: '25px'}}  />
					<span className="ms-2"> {t('he_pro_with_his')} </span>
				</Link>
				<Link to="/security" className="dropdown-item ai-icon">
					<img src={security} alt="security" style={{ width: '25px', height: '25px'}}  />
					<span className="ms-2"> {t('he_pro_sec')} </span>
				</Link>
				<Link to="/support-ticket" className="dropdown-item ai-icon">
					<img src={support_ticket} alt="support-ticket" style={{ width: '25px', height: '25px'}}  />
					<span className="ms-2"> {t('support_ticket')} </span>
				</Link>
				<Link to="" className="dropdown-item ai-icon">
					<img src={log_out} alt="log out"  style={{ width: '25px', height: '25px'}} />
					<span onClick={logoutSubmit} className="ms-2"> {t('he_pro_logout')} </span>
				</Link>
			</Dropdown.Menu>
		</Dropdown>
	</ul>
        );
    }

const [searchBut, setSearchBut] = useState(false);	
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return ( 
    <div style={{ zIndex: 998 }} className="header border-bottom">
        <div className="header-content">
		    <nav className="navbar navbar-expand">
		      <div className="collapse navbar-collapse justify-content-between">
		        <div className="header-left">
					<div
						className="dashboard_bar"
						style={{ textTransform: "capitalize" }}
					  >
						{finalName.join(" ").length === 0
						  ? "OddBet"
						  : finalName.join(" ") === "dashboard dark"
						  ? "OddBet"
						  : finalName.join(" ")}
					</div>
		       </div>

			<div className="nav-item d-flex align-items-center">
				<div className="input-group search-area">
					<input type="text" 
						className={`form-control ${searchBut ? "active" : ""}`}
						placeholder="Search.." 
					/>
					<span className="input-group-text" onClick={() => setSearchBut(!searchBut)}>
						<Link to={"#"}><i className="flaticon-381-search-2"></i></Link>
					</span>
				</div>
			</div> 


		        <ul className="navbar-nav header-right">

					 {AuthButtons}
			    </ul>
		      </div>
		    </nav>
        </div>
    </div>
  );
};

export default Header;
